<template>
  <footer>
    <div class="wrapper">
      <!--div class="bottom-img"></div-->
      <div class="nav-bottom">
        <ul class="sns" v-if="siteOption">
          <!-- <li @click="siteOption['sns2Link']">
            <img src="@/assets/img/telegram.svg" />
            <span>{{siteOption['sns2']}}<em>{{siteOption['sns2Id']}}</em></span>
          </li>
          <li @click="siteOption['sns1Link']">
            <img src="@/assets/img/kakao.svg" />
            <span>{{siteOption['sns1']}}<em>{{siteOption['sns1Id']}}</em></span>
          </li>
          <li v-if="siteOption['sns3']" @click="siteOption['sns3Link']">
            <img src="@/assets/img/zalo.svg" />
            <span>{{siteOption['sns3']}}<em>{{siteOption['sns3Id']}}</em></span>
          </li> -->
          <li></li>
          <!-- <li>
            <a href="https://t.me/Kg7942" target="_blank"><img src="../assets/img/main/telegram.png"></a>
          </li> -->
          <li>
            <a href="http://xn--299ap91chpg.com" target="_blank"><img src="../assets/img/main/domain.png"></a>
          </li>
        </ul>
        <ul class="calogo">
          <li><img src="@/assets/img/ca_logo1.png" /></li>
          <li><img src="@/assets/img/ca_logo2.png" /></li>
          <li><img src="@/assets/img/ca_logo3.png" /></li>
          <li><img src="@/assets/img/ca_logo4.png" /></li>
          <li><img src="@/assets/img/ca_logo5.png" /></li>
          <li><img src="@/assets/img/ca_logo6.png" /></li>
          <li><img src="@/assets/img/ca_logo7.png" /></li>
          <li><img src="@/assets/img/ca_logo8.png" /></li>
          <li><img src="@/assets/img/ca_logo9.png" /></li>
          <li><img src="@/assets/img/ca_logo10.png" /></li>
        </ul>
        <div class="footerInfo">
          <div class="footerl">
            <a class="flogo" @click="goPageByName('main')">
              <span class="copyright">Copyright 2024 KEEPGOING. All Rights Reserved.</span>
            </a>
          </div>
          <div class="footerr">
            <!--ul class="footerNav">
              <li @click="goPageByName('qnaWrite')">{{ $t('front.common.qna')}}</li>
              <li @click="goPageByName('notice')">{{ $t('front.common.notice')}}</li>
              <li @click="goPageByName('faq')">{{ $t('front.common.faq')}}</li>
              <li @click="goPageByName('bettingGuide')">{{ $t('front.common.bettingGuide')}}</li>
              <li @click="goPageByName('result')">{{ $t('front.common.gameResult')}}</li>
              <li @click="goPageByName('boardList')">{{ $t('front.common.board')}}</li>
            </ul-->
          </div>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Footer',
  computed: {
    ...mapState([
      'siteOption'
    ])
  }
}
</script>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/common.css"></style>
